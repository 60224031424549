@if (orderTimeExpired$ | async) {
  <div class="alert alert-danger">
    <strong>
      {{'PAGES.PRODUCTS.EXTRA.TIME_EXPIRED' | translate}}
    </strong><br>
    {{'PAGES.PRODUCTS.EXTRA.TIME_EXPIRED_BODY' | translate}}
  </div>
} @else if (upsellAlreadyPerformed$ | async) {
  <div class="alert alert-danger">
    <strong>
      {{'PAGES.PRODUCTS.EXTRA.UPSELL_ALREADY_PERFORMED' | translate}}
    </strong><br>
    <span [innerHTML]="'PAGES.PRODUCTS.EXTRA.UPSELL_ALREADY_PERFORMED_BODY' | translate"></span>
  </div>
} @else {
  <mat-stepper labelPosition="bottom" animationDuration="0" [linear]="true" (selectionChange)="skipValidationOnBackNavigation($event)" #stepper>

    <mat-step #step1 [stepControl]="form.controls.expositionPeriodId">
      <ng-template matStepLabel>
        {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.LABEL' | translate}}
      </ng-template>
      <ng-template matStepContent>
        <app-extra-step-1 class="step-content"
                          [isActive]="stepper.selectedIndex === stepper.steps.toArray().indexOf(step1)"
                          [stepper]="stepper">
        </app-extra-step-1>
      </ng-template>
    </mat-step>

    <mat-step #step2 [stepControl]="form.controls.organisedVisitId">
      <ng-template matStepLabel>
        {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_2.LABEL' | translate}}
      </ng-template>
      <ng-template matStepContent>
        <app-extra-step-2 class="step-content"
                          [isActive]="stepper.selectedIndex === stepper.steps.toArray().indexOf(step2)"
                          [stepper]="stepper">
        </app-extra-step-2>
      </ng-template>
    </mat-step>

    <mat-step #step3 [stepControl]="form.controls.organisedVisitId">
      <ng-template matStepLabel>
        {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_3.LABEL' | translate}}
      </ng-template>
      <ng-template matStepContent>
        <app-extra-step-3 class="step-content"
                          [isActive]="stepper.selectedIndex === stepper.steps.toArray().indexOf(step3)"
                          [stepper]="stepper">
        </app-extra-step-3>
      </ng-template>
    </mat-step>

  </mat-stepper>
}
