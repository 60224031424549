<div class="step-content" [ngClass]="responsiveService.activeLayout">
  <mat-card>
    <mat-card-content>
<!--      <h1>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.TITLE' | translate}}</h1>-->
<!--      <p [innerHTML]="'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.ORDER_BODY' | translate"></p>-->

      @if (order() === null) {
        <div class="alert alert-danger">
          <strong>
            {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.UNKNOWN' | translate}}
          </strong>
        </div>
      }

      @if (order(); as o) {
<!--        <h2>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.ORDER_TITLE' | translate}}</h2>-->
        <h2>{{ 'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.GREETING' | translate:{name: o.personName} }}</h2>

        <p class="no-margin">
          {{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.ORDER_TITLE' | translate}}<br>
<!--          {{o.orderNumber}}<br>-->
<!--          {{o.startDate | dutchDate: (dateFormat$|async):'europe/amsterdam': translateService.currentLang}}<br>-->
<!--          {{orderTitle()}}-->
        </p>

        <mat-list class="single-row">
          <mat-list-item>
            <mat-icon matListItemIcon>tag</mat-icon>
            <span matListItemTitle>{{o.orderNumber}}</span>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>event</mat-icon>
            <span matListItemTitle>{{o.startDate | dutchDate: (dateFormat$|async):'europe/amsterdam': translateService.currentLang}}</span>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>directions_boat</mat-icon>
            <span matListItemTitle>{{orderTitle()}}</span>
          </mat-list-item>
        </mat-list>

        @if (articles$ | async) {
<!--          <h2>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.ORDERED_ARTICLES_TITLE' | translate}}</h2>-->

          <p>
            @for (article of (articles$ | async); track article.id; let last = $last) {
              {{article.quantity}} x {{article.name}}

              @if (!last) {
                <br>
              }
            }
          </p>

<!--          <mat-list>-->
<!--            @for (article of (articles$ | async); track article.id; let last = $last) {-->
<!--              <mat-list-item>-->
<!--                <img matListItemIcon [src]="imageBaseUrl + article.img"/>-->
<!--                <span matListItemTitle>{{article.quantity}} x {{article.name}}</span>-->
<!--                <span matListItemLine>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.QUANTITY' | translate:{quantity: article.quantity} }}</span>-->
<!--              </mat-list-item>-->
<!--            }-->
<!--          </mat-list>-->
        }

        <p [innerHTML]="'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.EXPLANATION' | translate"></p>
      }

      @if (loading()?.loading === true && order() !== null) {
        <div class="loading-wrapper">
          <mat-spinner></mat-spinner>
          <p>{{'PAGES.PRODUCTS.EXTRA.STEPS.STEP_1.LOADING' | translate}}</p>
        </div>
      }
    </mat-card-content>
  </mat-card>

  @if (order()) {
    <button mat-raised-button matStepperNext color="accent">
      {{'FORMS.NEXT' | translate}}
      <mat-icon iconPositionEnd>navigate_next</mat-icon>
    </button>
  }
</div>



