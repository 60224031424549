import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {notNullOrUndefined, nullOrUndefined} from "./UtilityFunctions";
import {debounceTime, distinctUntilChanged, filter, Observable, take} from "rxjs";
import {startWithTap} from "./CustomRxjsOperators";
import {parsePhoneNumber} from "libphonenumber-js";
import {ArticleValue} from "../components/extra-article-tile/article-tile.component";
import {TourComponent} from "../pages/products/tour/tour.component";

export const fullNameValidator: ValidatorFn = (control: AbstractControl<string | null | undefined>): ValidationErrors | null => {
  const nameParts = control.value?.trim().split(/\s+/).length ?? 0;
  return nameParts <= 1 ? {forbiddenName: {value: control.value}} : null;
};

export function requiredCustomMessage(translationKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return value === null || value === undefined || (!value && value !== false) ? {customRequired: {translationKey: translationKey}} : null;
  };
}

export const requiredUpsellValidator: ValidatorFn = (control: AbstractControl<Array<ArticleValue>>): ValidationErrors | null => {
  const hasAnyArticles = control.value.some(order => order.quantity > 0);

  return hasAnyArticles ? null : {requiredUpsell: {}};
};

export const phoneValidator: ValidatorFn = (control: AbstractControl<string | null | undefined>): ValidationErrors | null => {
  if (nullOrUndefined(control.value)) {
    return null;
  }

  try {
    const result = parsePhoneNumber(control.value, 'NL');

    if (result.isValid()) {
      return null;
    }
  } catch (e: unknown) {
    return {'phone': {}};
  }

  return {'phone': {}};
};

export const peopleCountValidator: ValidatorFn = (control: AbstractControl<TourComponent['form']['controls']['mainOrder']['value']>): ValidationErrors | null => {
  const adultCount = control.value.adults ?? 0;
  const seniorCount = control.value.seniors ?? 0;
  const kidCount = control.value.kids ?? 0;
  const specialTicketCount = control.value.specialTickets?.reduce((acc, ticket) => acc + (ticket.quantity ?? 0), 0) ?? 0;

  const peopleCount = adultCount + seniorCount + kidCount + specialTicketCount;

  if (peopleCount === 0) {
    return {noPeople: {}};
  }

  return null;
};

export const duplicateVoucherValidator: ValidatorFn = (control: AbstractControl<TourComponent['form']['controls']['vouchers']['value']>): ValidationErrors | null => {
  const vouchers = control.value.filter(notNullOrUndefined);

  const hasDuplicates = (new Set(vouchers)).size !== vouchers.length;

  if (hasDuplicates) {
    return {duplicateVouchers: {}};
  }

  return null;
};

export const duplicateGiftCardValidator: ValidatorFn = (control: AbstractControl<TourComponent['form']['controls']['vouchers']['value']>): ValidationErrors | null => {
  const giftCards = control.value.filter(notNullOrUndefined);

  const hasDuplicates = (new Set(giftCards)).size !== giftCards.length;

  if (hasDuplicates) {
    return {duplicateGiftCards: {}};
  }

  return null;
};

export const duplicateDiscountVoucherValidator: ValidatorFn = (control: AbstractControl<FormArray<FormControl<string | null>>>): ValidationErrors | null => {
  const vouchers = control.value.controls.map(control => control.value).filter(notNullOrUndefined);

  const hasDuplicates = (new Set(vouchers)).size !== vouchers.length;

  if (hasDuplicates) {
    return {duplicateDiscountVouchers: {}};
  }

  return null;
};

export function triggerDeepValidation(formItem: FormGroup | FormArray | AbstractControl, emit = false) {
  formItem.markAllAsTouched();
  formItem.markAsDirty({onlySelf: false});
  formItem.markAsTouched({ onlySelf: false });
  formItem.updateValueAndValidity({onlySelf: false, emitEvent: emit});

  if (formItem instanceof FormGroup) {
    Object
      .keys(formItem.controls)
      .map(key => formItem.controls[key])
      .forEach(control => triggerDeepValidation(control, emit));
  }

  if (formItem instanceof FormArray) {
    for (const control of formItem.controls) {
      triggerDeepValidation(control, emit);
    }
  }
}

export function getAsyncFormValidationStatus(form: AbstractControl): Observable<AbstractControl['status']> {
  return form.statusChanges
    .pipe(
      startWithTap(() => triggerDeepValidation(form, true)),
      debounceTime(1),
      distinctUntilChanged(),
      filter(status => status !== 'PENDING'),
      take(1)
    );
}
